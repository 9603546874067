<template>
  <div>
    <div class="title">员工保险设置</div>

    <el-divider></el-divider>

    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="员工名称：" prop="name">
        <el-input disabled v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item class="holiday" label="保险费用承担：" prop="insurance_seleted">
        <el-checkbox-group v-model="form.insurance_seleted">
          <el-checkbox
            v-for="item in insuranceList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="扣除社保费用：" prop="is_social_security">
        <el-radio-group v-model="form.is_social_security">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="保险费用承担：" prop="insurance_premium_type">
        <el-radio-group v-model="form.insurance_premium_type">
          <el-radio label="1">企业承担全部</el-radio>
          <el-radio label="2">员工承担全部</el-radio>
          <el-radio label="3">按比例共同承担</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- save -->
      <div class="custom_button no_select operation_button" @click="onSubmit">
        保存
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        ids: "",
        name: "",
        is_social_security: "1",
        insurance_premium_type: "3",
        insurance_seleted: [],
      },
      insuranceList: [],
      rules: {
        is_social_security: [
          { required: true, message: "请选择扣除社保费用", trigger: "blur" },
        ],
        insurance_premium_type: [
          { required: true, message: "请选择保险费用承担", trigger: "blur" },
        ],
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.form.name = this.$route.query.names;
    this.form.ids = this.$route.query.ids;

    this.getInsuranceList()
      .then((res) => {
        console.log(res);
        this.insuranceList = res.data.data;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    ...mapActions("employee", ["getInsuranceList", "postEmployeeRegular"]),

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.insurance = this.form.insurance_seleted.join(",");
          console.log(this.form);
          let loading = this.$loading();
          this.postEmployeeRegular(this.form)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success("信息提交成功");
              this.$router.back();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error("信息提交失败");
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: #222222;
  width: 140px !important;
}

::v-deep .el-form-item__content {
  margin-left: 140px !important;
}
.el-radio.is-checked {
  ::v-deep .el-radio__inner {
    border-color: #4db9d5;
    background: #4db9d5;
  }

  ::v-deep .el-radio__label {
    color: #606266;
  }
}

.holiday {
  ::v-deep {
    .el-form-item__content {
      margin-left: 0 !important;
    }

    .el-checkbox__inner:hover {
      border-color: #4db9d5;
    }

    .el-checkbox__input {
      &.is-checked {
        .el-checkbox__inner {
          background-color: #4db9d5;
          border-color: #4db9d5;
        }
      }

      &.is-focus {
        .el-checkbox__inner {
          border-color: #4db9d5;
        }
      }
    }

    .el-checkbox__label {
      color: #495060;
    }
  }
}

.el-input {
  width: 400px;
  height: 48px;
  font-size: 14px;
  color: #222222;
}

.sp_input {
  position: relative;
  width: max-content;
  display: inline-block;

  .sp_input_suffix {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 14px;
    color: #495060;
  }
}

.operation_button {
  width: 142px;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  margin-top: 30px;
}
</style>
